// extracted by mini-css-extract-plugin
export var Section = "FreeGuide-module--Section--38Zz4";
export var CTAContainer = "FreeGuide-module--CTAContainer--2S9H5";
export var ImageContainer = "FreeGuide-module--ImageContainer--2pjQ_";
export var Image = "FreeGuide-module--Image--3j89v";
export var Copy = "FreeGuide-module--Copy--1L7jC";
export var BenefitsContainer = "FreeGuide-module--BenefitsContainer--2OPNv";
export var MaxWidthContainer = "FreeGuide-module--MaxWidthContainer--9gyhe";
export var Icon = "FreeGuide-module--Icon--Lutqi";
export var PensionReviewContainer = "FreeGuide-module--PensionReviewContainer--2tkS9";
export var Paragraphs = "FreeGuide-module--Paragraphs--SujXZ";
export var OptionsContainer = "FreeGuide-module--OptionsContainer--3tFO6";
export var OptionsCard = "FreeGuide-module--OptionsCard--2BKnK";
export var FormSection = "FreeGuide-module--FormSection--LU9K0";
export var ContentContainer = "FreeGuide-module--ContentContainer--2sM48";
export var CopyContainer = "FreeGuide-module--CopyContainer--253U7";
export var FormContainer = "FreeGuide-module--FormContainer--yadmv";